import { ToastMessageType } from './../components/layout/toast-message/toast-message';
import { BaseIdentity } from '../domain-models/base-identity';
import { ExternalViewModelInterface } from './external-view-model.interface';
import { PropertyViewModelInterface } from './property-view-model.interface';
import { ModelInterface } from '../domain-models/model.interface';
import { AggregateMetaData, ExternalMetaData, DomainModelMetaData, MetaDataUtils, StringMetaData, AccessMode } from '../meta-data';
import { ExternalDomainModelChangedEventArgs } from './external-domain-model-changed-event-args';
import { EventEmitter } from '@angular/core';
import { SourceMessage } from './message-container';
import { BaseError } from '../messages/base-error';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { BehaviorSubject, firstValueFrom, merge, Observable, Subject } from 'rxjs';
import { map, debounceTime, takeUntil, take, timeout, filter } from 'rxjs/operators';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { MessageCodes } from '../resources/message-codes';
import { PresentationCache } from '../cache/presentation-cache';
import { UIStarter } from '../starter/ui-starter';
import { CodeValueMessageArg } from '../resources/code-value-message-arg';
import { ZoomResult } from '../domain-models/zoom/zoom-result';
import { Filter, FilterOperators } from '../domain-models/find-options/filter';
import { ZoomUIStarterArgs } from './zoom/zoom-ui-starter-args';
import { ZoomStarterMode } from '../domain-models/zoom/zoom-starter-mode';
import { ZoomOrchestratorViewModelResolver } from './zoom/zoom-orchestrator-view-model-resolver';
import { ZoomAdvancedOptions } from '../domain-models/find-options/zoom-advanced-options';
import { CoreModel } from '../domain-models/core-model';
import { IdentityTypeInspector } from '../decorators/identity-type.decorator';
import { ExternalDomainModelTypeNameInspector } from './decorators/external-domain-model-type-name.decorator';
import { ExternalDomainModelTypeInspector } from './decorators/external-domain-model-type.decorator';
import { ViewModelFactory } from './view-model-factory';
import { InternalViewModelTypeInspector } from './decorators/internal-view-model-type.decorator';
import { ViewModelInterface } from './view-model.interface';
import { AggregateElementViewModel } from './aggregate-element-view-model';
import { AutoCompleteExternalOptions } from '../domain-models/autocomplete/auto-complete-external-options';
import { OCCAuditDeactivableModel } from '../domain-models/occ-audit-deactivable-model';
import { DeactivableModelInterface } from '../domain-models/deactivable-model.interface';
import { ToastMessage } from '../components/layout/toast-message/toast-message';
import { ClassInformationInterface, ClassInformationType, LogService, Message } from '@nts/std/src/lib/utility';
import { ClassType, TypeHelpOptions, TypeMetadata, defaultMetadataStorage, plainToClass } from '../serialization/class-transformer';
import { PropertyViewModelFactory } from './property-view-model-factory';
import { CustomPropertyViewModelDecorator } from './decorators/custom-property-view-model.decorator';
import { StringDecorator } from '../domain-models/decorators/string.decorator';
import { StringPropertyViewModel } from './base-type/string-property-view-model';
import { OrderBy, OrderByType } from '../domain-models/autocomplete/auto-complete-options';
import { FindValuesOptions } from '../domain-models/find-options/find-values-options';
import { PropertyViewModel } from './property-view-model';
import { NumericPropertyViewModel } from './base-type/numeric-property-view-model';
import { NNumericPropertyViewModel } from './base-type/nnumeric-property-view-model';
import { CloneTypes } from '../domain-models/clone-types.enum';
import { DomainModelState } from '../domain-models/domain-model-state';
import { CollectionViewModel } from './collection-view-model';
import { RequiredValidator } from '../domain-models/decorators/validations/required-validation';

export class ExternalViewModel<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>
    extends AggregateElementViewModel<TModel, TIdentity>
    implements ExternalViewModelInterface, ClassInformationInterface {

    //#region PUBLIC VARS

    /**
     * Default abilitato, all'inizializzazione dell'external se il suo backing field è popolato ma il suo ref non lo è esegue una setcodevalue che permette di popolare correttamente il suo ref
     */
    checkAndFixNullExternal: boolean = true;

    classType = ClassInformationType.ExternalViewModel;
    autocomplete = false;
    minSearchLength = 3;
    autocompleteWithLanguage = false;
    externalDomainModelChanged = new EventEmitter<ExternalDomainModelChangedEventArgs>();
    decodeCompleted = new EventEmitter<void>();
    externalPresentationCompleted = new EventEmitter<void>();
    externalMetaData: ExternalMetaData;
    backingFieldCanNotifyModified = true;
    execDecodeEmitter = new Subject<{ [key: string]: string | number; }>();
    resettedData = new Subject<void>();
    parentIdentity$ = new BehaviorSubject<ExternalViewModelInterface | PropertyViewModelInterface>(null);
    parentPlainIdentity$ = new BehaviorSubject<{ [key: string]: string | number }>(null);
    localReplicaAutocompleteDataRequested = new Subject<TModel>();

    // Lista di property da utilizzare per la descrizione

    /**
     * Lista di property da utilizzare per la descrizione
     * Se non viene passata, si utilizza la funzione getMainDescriptionPropertiesList
     */
    decodeProperties: string[] | null = null;
    searchProperties: string[] | null = null;
    orderByPropertyNames: OrderBy[] | null = null;

    /**
     * Le property PascalCase dichiarate qui, vengono aggiunte nella chiamata ma non vengono utilizzate per la description
     * Utile nel caso si voglia aggiungere delle logiche di filtro basate su queste property ad esempio verificare IsDefault
     */
    additionalOutputProperties: string[] | null = [];

    autocompleteProperties: string[] = [];
    joinOperator: string = ' - ';


    additionalFilters: Filter[] | null = null;

    protected internalDefaultColor: string = null;
    protected internalActiveColor: string = null;
    protected internalHoverColor: string = null;

    override get hasDecodeError(): boolean {
        return this._hasDecodeError;
    }
    override set hasDecodeError(value: boolean) {
        this._hasDecodeError = value;
    }

    override get isEnabled(): boolean {
        let isEnabled = this._isEnabled;
        if (this.codeProperties) {
            isEnabled = isEnabled && Array.from(this.codeProperties.values()).every(i => i.isEnabled);
        }
        return isEnabled;
    }
    override set isEnabled(value: boolean) {
        this._isEnabled = value;
        this.onPropertyChanged('isEnabled');
        if (this.codeProperties) {
            this.codeProperties.forEach(c => {
                c.isEnabled = value;
            });
        }
    }

    get defaultColor(): string {
        return this.internalDefaultColor;
    }
    set defaultColor(value: string) {
        this.internalDefaultColor = value;
        this.onPropertyChanged('defaultColor');
    }

    get activeColor(): string {
        return this.internalActiveColor;
    }
    set activeColor(value: string) {
        this.internalActiveColor = value;
        this.onPropertyChanged('activeColor');
    }

    get hoverColor() {
        return this.internalHoverColor;
    }
    set hoverColor(value: string) {
        if (this.internalHoverColor !== value) {
            this.internalHoverColor = value;
            this.onPropertyChanged('hoverColor');
        }
    }

    get zoomAddIsEnabled() {
        return this.internalZoomAddIsEnabled;
    }
    set zoomAddIsEnabled(value: boolean) {
        if (this.internalZoomAddIsEnabled !== value) {
            this.internalZoomAddIsEnabled = value;
            this.onPropertyChanged('zoomAddIsEnabled');
        }
    }

    get zoomAddIsVisible() {
        return this.internalZoomAddIsVisible;
    }
    set zoomAddIsVisible(value: boolean) {
        if (this.internalZoomAddIsVisible !== value) {
            this.internalZoomAddIsVisible = value;
            this.onPropertyChanged('zoomAddIsVisible');
        }
    }

    get zoomSearchIsEnabled() {
        return this.internalZoomSearchIsEnabled;
    }
    set zoomSearchIsEnabled(value: boolean) {
        if (this.internalZoomSearchIsEnabled !== value) {
            this.internalZoomSearchIsEnabled = value;
            this.onPropertyChanged('zoomSearchIsEnabled');
        }
    }

    get zoomSearchIsVisible() {
        return this.internalZoomSearchIsVisible;
    }
    set zoomSearchIsVisible(value: boolean) {
        if (this.internalZoomSearchIsVisible !== value) {
            this.internalZoomSearchIsVisible = value;
            this.onPropertyChanged('zoomSearchIsVisible');
        }
    }

    get zoomViewIsEnabled() {
        return this.internalZoomViewIsEnabled;
    }
    set zoomViewIsEnabled(value: boolean) {
        if (this.internalZoomViewIsEnabled !== value) {
            this.internalZoomViewIsEnabled = value;
            this.onPropertyChanged('zoomViewIsEnabled');
        }
    }

    get zoomViewIsVisible() {
        return this.internalZoomViewIsVisible;
    }
    set zoomViewIsVisible(value: boolean) {
        if (this.internalZoomViewIsVisible !== value) {
            this.internalZoomViewIsVisible = value;
            this.onPropertyChanged('zoomViewIsVisible');
        }
    }

    override get hasErrors() {
        // se ho un errore di "decodifica non trovata" allora ho un errore; in caso contario:
        //  siccome si tratta di una External, se il domain model è presente allora si tratta di valori salvati sul DB
        //  pertanto sono sicuramente validi e non hanno errori. Devo verificare lo stato di errore solo se
        //  non ho il domain model, e in tal caso è molto semplice: se era obbligatoria allora errore, altrimenti no errore
        if (
            this.hasDecodeError ||
            super.hasErrors
            // || (this.domainModel == null)
            // || (this.composedMessageContainerCollection.length > 0)
        ) {
            return true;
        } else {
            return false;
        }
    }

    get securityAccess(): AccessMode | null {
        return this._securityAccess;
    };

    get formattedValue() {
        if (this.securityAccess === AccessMode.Deny) {
            return 'Non accessibile';
        }
        const code = this.codeProperties?.values()?.next()?.value;
        if (code == null || code.getValue() == null) {
            return '';
        }
        const description = this.descriptionProperties?.values()?.next()?.value;
        if (description == null || description.getValue() == null) {
            return code.getValue();
        }
        return `${code.getValue()} - ${description.getValue()}`;
    }

    get externalDomainModelTypeName() {
        return this._externalDomainModelTypeName;
    }

    get externalDomainModelType() {
        return this._externalDomainModelType;
    }

    get propertyName(): string {
        return this._propertyName;
    }

    get descriptionProperties(): PropertyViewModelInterface[] {
        return this._descriptionProperties;
    }

    get dependentDomainModelName(): string {
        return this._dependentDomainModelName;
    }

    get decodeInProgress(): boolean {
        return this._decodeInProgress;
    }

    get codeProperties(): Map<string, PropertyViewModelInterface> {
        return this._codeProperties;
    }

     /**
     * @deprecated 
     * Aggiungi in [decodeProperties] il codice se vuoi visualizzarlo
     */
    showCode = true;

    @CustomPropertyViewModelDecorator()
    @StringDecorator({ displayNameKey: 'ExternalViewModel_AutomaticDecodeDescription_DisplayName' })
    get automaticDecodeDescription(): StringPropertyViewModel {
        if (this._automaticDecodeDescription == null) {
            this.setAutomaticAutocomplete();
            const init = PropertyViewModelFactory.createPVMInitializationInfo<StringMetaData>(
                this, 'automaticDecodeDescription', null, this, this.modifiedSubscriber, this.eventDispatcher, null, false, false
            );
            init.customGetter = () => {
                return this.autoCompleteOptions.outputProperties.map((curr, index) => {
                    // il primo output properties è sempre l'id
                    if (index === 0) {
                        // quindi lo salto
                        return null;
                    }
                    const camelCaseProperty = MetaDataUtils.toCamelCase(curr);
                    const property = this.getProperty(camelCaseProperty)
                    if (!property) {
                        const className = ExternalDomainModelTypeNameInspector.getValue(this) + 'ExtViewModel';
                        LogService.warn(`Missing ${camelCaseProperty} property view model in ${className}`);
                        return null;
                    } else {
                        return property.formattedValue;
                    }
                }).filter(item => item).join(' - ');
            }
            this._automaticDecodeDescription = new StringPropertyViewModel(init);
        }
        return this._automaticDecodeDescription;
    }
    //#endregion PUBLIC VARS

    //#region PROTECTED VARS
    /**
     * @deprecated utilizza le property del external view model:
     * - this.additionalFilters
     * - this.decodeProperties
     * - this.searchProperties
     * - this.orderByPropertyNames
     */
    protected autoCompleteOptions: AutoCompleteExternalOptions;
    protected externalListOptions: FindValuesOptions;
    //#endregion PROTECTED VARS

    //#region PRIVATE VARS
    private _securityAccess: AccessMode | null = null;
    private _decodeInProgress = false;
    private destroy$: Subject<boolean> = new Subject<boolean>();
    private _externalDomainModelType: any;
    private _hideIsActiveField = true; // Campo necessario per nascondere isActive nell'autocomplete
    private _externalDomainModelTypeName: string;
    private _identityType: any;
    private _codeProperties: Map<string, PropertyViewModelInterface>;
    private _descriptionProperties = new Array<PropertyViewModelInterface>();
    private _propertyName: string;
    private _automaticDecodeDescription: StringPropertyViewModel;
    private _hasDecodeError: boolean;
    private _dependentDomainModelName: string;
    private internalZoomAddIsEnabled = true;
    private internalZoomAddIsVisible = true;
    private internalZoomSearchIsEnabled = true;
    private internalZoomSearchIsVisible = true;
    private internalZoomViewIsEnabled = true;
    private internalZoomViewIsVisible = true;
    //#endregion PRIVATE VARS

    //#region CONSTRUCTOR
    constructor() {

        super();

        this._externalDomainModelType = ExternalDomainModelTypeInspector.getValue(this);

        if (this._externalDomainModelType === undefined) {
            throw new Error(
                `MetaData ${ExternalDomainModelTypeInspector.META_DATA_KEY} not defined. You must use ${ExternalDomainModelTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this._externalDomainModelTypeName = ExternalDomainModelTypeNameInspector.getValue(this);

        if (this._externalDomainModelTypeName === undefined) {
            throw new Error(
                `MetaData ${ExternalDomainModelTypeNameInspector.META_DATA_KEY} not defined. You must use ${ExternalDomainModelTypeNameInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this._identityType = IdentityTypeInspector.getValue(this);

        if (this._identityType === undefined) {
            throw new Error(
                `MetaData ${IdentityTypeInspector.META_DATA_KEY} not defined. You must use ${IdentityTypeInspector.DECORATOR_NAME} in ${this.constructor.name}.`
            );
        }

        this.execDecodeEmitter.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe((identity) => {
            this.execDecodeHandler(identity);
        });

    }
    //#endregion CONSTRUCTOR

    //#region PUBLIC METHODS
    override async postInit(): Promise<void> {

        // anche se è async è importante non usare l'await visto che deve aspettare che il rootviewmodel sia disponibile
        if (!this.isMock) {
            this.checkAndSetParentIdentity().then(() => this.checkAndSetCustomParentIdentity());
            if (this.externalMetaData?.dependentAggregateMetaData?.rootMetaData?.identityNames?.length === 1) {
                const code = this.codeProperties.get(MetaDataUtils.toCamelCase(this.externalMetaData?.dependentAggregateMetaData?.rootMetaData?.identityNames[0]));
                code.setIdentityOnSet = true;
            }

            this.decodeCompleted.subscribe(async () => {
                const dm = new this.externalDomainModelType();
                if (dm instanceof OCCAuditDeactivableModel) {
                    const ovm = this.externalRetriever as CoreOrchestratorViewModelInterface;
                    if (!ovm.rootViewModel) {
                        await firstValueFrom(ovm.rootViewModelChanged.pipe(filter(() => ovm.rootViewModel != null)))
                    }
                    const rootDm = ovm.rootViewModel.getDomainModel() as DeactivableModelInterface;
                    if (ovm.isDeactivable && rootDm.isActive) {
                        const dmCurrent = this.getDomainModel();
                        if (dmCurrent instanceof OCCAuditDeactivableModel ? (dmCurrent.isActive != undefined && !dmCurrent.isActive) : false) {
                            const title = MessageResourceManager.Current.getMessage(MessageCodes.Warning);
                            const message = MessageResourceManager.Current.getMessage('std_ObjectIsNotActive');
                            const toast = {
                                title,
                                message,
                                type: ToastMessageType.info
                            } as ToastMessage;
                            ovm.toastMessageService.showToast(toast);
                        }
                    }
                }

            });
        }

        if (!this.domainModel && this.isMock == false) {
            for (const [key, value] of this.codeProperties) {
                const oldValue = this.backingFieldCanNotifyModified;
                this.backingFieldCanNotifyModified = false;
                await value.setCurrentValueWithDefaultValueFromLayoutMetaData();
                this.backingFieldCanNotifyModified = oldValue;
            }
        }
    }

    override onDestroy() {
        super.onDestroy();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    override validate() {

        if (this.skipValidation || this.isMock) {
            return;
        }

        // cancello gli errori su tutte le property
        this.clearErrors();

        if (this.hasDecodeError) {
            // this.codeProperties.forEach((code) => {

            const arr = new Array<CodeValueMessageArg>();
            const arg = new CodeValueMessageArg();
            arg.code = MessageCodes.ErrorCodeNotFound_TAG_NomeCampo;
            arg.value = this.metadataShortDescription;
            arr.push(arg);
            const errorMessage = MessageResourceManager.Current.getMessageWithArgs(MessageCodes.ErrorCodeNotFound, arr);

            const e = new BaseError();
            e.description = errorMessage;
            e.code = MessageCodes.ErrorCodeNotFound;
            e.propertyName = this.propertyName;

            this.addError(SourceMessage.ValidationDecode, e)
        } else {
            if (this.isRequired == true) {
                const validator = new RequiredValidator(this.metadataShortDescription);
                if (!validator.validate(this.domainModel)) {
                    const e = new BaseError();
                    e.description = validator.errorMessage;
                    e.code = validator.messageCode;
                    e.propertyName = this.propertyName;
                    this.addError(SourceMessage.Validation, e)
                }
            }
        }
    }

    // override addError(sourceMessage: SourceMessage, error: BaseError) {
    //     if (this.codeProperties != null) {
    //         this.codeProperties.forEach(code => {
    //             this.codeProperties.get(code.propertyName).addError(sourceMessage, error);
    //         });
    //     }
    //     this.updateCurrentErrors();
    //     this.onErrorStatusChanged.next();
    // }

    async findExternal(codePropertyViewModel: PropertyViewModelInterface): Promise<ZoomResult> {
        if (this.parentIdentity$.value != null) {
            if (this.parentPlainIdentity$.value == null) {
                return ZoomResult.empty();
            }

        }
        try {
            this.eventDispatcher.onActionInProgress.next(true);
            const pvm = codePropertyViewModel;
            const zoomOpenFilter = this.zoomOpenFilter();
            const automaticFilters = this.createAutomaticFilters(pvm, this._descriptionProperties);
            if (automaticFilters != null && automaticFilters.length > 0) {
                zoomOpenFilter.filters.push(...automaticFilters);
            }
            const lockedFilters = this.createLockedFilters(pvm, this._descriptionProperties);
            if (lockedFilters != null && lockedFilters.length > 0) {
                zoomOpenFilter.filters.push(...lockedFilters);
            }
            const args = this.createZoomUIStarterArgs(this.domainModelMetaData, zoomOpenFilter);
            args.zoomStarterMode = ZoomStarterMode.F6;
            const zoomOrchestratorViewModel = ZoomOrchestratorViewModelResolver.createZoomOrchestratorViewModel();
            await zoomOrchestratorViewModel.initialize(args);
            this.eventDispatcher.onActionInProgress.next(false);
            this.eventDispatcher.beforeZoomOpen.next()
            const res = await this.externalRetriever.modalService.showZoomAsync(zoomOrchestratorViewModel);
            if (!res.cancel) {
                const r = res.result;
                if (r.result !== ZoomResult.empty().result) {
                    await this.retriveAndSetDomainModelFromZoomResult(r);
                    return r;
                }
            }
            return ZoomResult.empty();
        } catch (error) {
            LogService.error('findExternal failed', error);
            throw new Error(MessageResourceManager.Current.getMessage(MessageCodes.ZoomFindException));
        } finally {
            this.eventDispatcher.onActionInProgress.next(false);
        }
    }

    async retriveAndSetDomainModelFromZoomResult(result: ZoomResult) {
        await this.retriveAndSetDomainModelFromJSON(result.result, true);
    }

    zoomOpenFilter(): ZoomAdvancedOptions {
        return new ZoomAdvancedOptions();
    }

    execExternalList(
        _languageCode?: string,
        showCodeInDescription = true,
        basePlainPascalCaseFixedIdentity = null,
        decodeProperties: string[] | null = null,
        orderByPropertyNames: OrderBy[] | null = null,
        additionalFilters: Filter[] | null = null,
        joinOperator: string | undefined = undefined
    ): Observable<{ description: string, identity: { [key: string]: string | number }, all: { [key: string]: string | number; }[] }[]> {

        joinOperator = joinOperator ?? this.joinOperator
        additionalFilters = additionalFilters || this.additionalFilters || [];
        decodeProperties = decodeProperties || this.decodeProperties || this.getMainDescriptionPropertiesList();

        // Rimuovo tra le decode properties quelle custom
        decodeProperties = decodeProperties.filter((d) => {
            return this.externalMetaData.dependentAggregateMetaData.rootMetaData.allPropertyNames.map((a) => a.toLowerCase()).indexOf(d.toLowerCase()) > -1
        })

        orderByPropertyNames = orderByPropertyNames || this.orderByPropertyNames || Array.from(new Set(decodeProperties)).map((propertyName: string) => {
            return new OrderBy({ propertyName, sortType: OrderByType.Ascending });
        });

        let findValuesOptions = new FindValuesOptions();

        findValuesOptions.outputProperties = Array.from(new Set([...this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames, ...decodeProperties, ...this.additionalOutputProperties]));
        findValuesOptions.orderByPropertyNames = orderByPropertyNames;

        if (basePlainPascalCaseFixedIdentity) {
            const baseKeys = Object.keys(basePlainPascalCaseFixedIdentity);

            for (const baseKey of baseKeys) {
                const newFilter = new Filter();
                newFilter.name = baseKey;
                newFilter.value = basePlainPascalCaseFixedIdentity[baseKey];
                findValuesOptions.filters.push(newFilter);
            }
        }

        findValuesOptions.filters = [
            ...findValuesOptions.filters,
            ...additionalFilters
        ].filter((existFilter) => existFilter != null);

        const dm = new this.externalDomainModelType();
        this.updateIsActivePropertyVisibility(dm);
        if (dm instanceof OCCAuditDeactivableModel) {
            const prop = 'IsActive';
            if (findValuesOptions.outputProperties.indexOf(prop) === -1) {
                findValuesOptions.outputProperties.push(prop);
            }
        }

        return this.externalRetriever
            .findValues(findValuesOptions, this.domainModelName, this.domainModelFullName)
            .pipe(map(r => r.result.map((properties: any[]) => {
                return this.parseAutocompleteDataFromPropertyArray(
                    properties,
                    showCodeInDescription,
                    findValuesOptions.outputProperties,
                    basePlainPascalCaseFixedIdentity,
                    decodeProperties,
                    joinOperator
                );
            })));











        // const findValuesOptions = new FindValuesOptions();

        // if (this.externalListOptions) {
        //     findValuesOptions.filters = this.externalListOptions.filters;
        //     findValuesOptions.outputProperties = this.externalListOptions.outputProperties;
        // } else {

        //     const code = this.codeProperties.values().next().value as PropertyViewModelInterface;
        //     const description = this.descriptionProperties.values().next().value as PropertyViewModelInterface;

        //     findValuesOptions.outputProperties = [
        //         MetaDataUtils.toPascalCase(code.propertyName),
        //         MetaDataUtils.toPascalCase(description.propertyName)
        //     ]
        // }

        // const dm = new this.externalDomainModelType();
        // this.updateIsActivePropertyVisibility(dm);
        // if (dm instanceof OCCAuditDeactivableModel) {
        //     const prop = 'IsActive';
        //     if (findValuesOptions.outputProperties.indexOf(prop) === -1) {
        //         findValuesOptions.outputProperties.push(prop);
        //     }
        // }

        // return this.externalRetriever
        //     .findValues(findValuesOptions, this.domainModelName, this.domainModelFullName)
        //     .pipe(map(r => r.result.map((properties: any[]) => {
        //         return this.parseAutocompleteDataFromPropertyArray(
        //             properties,
        //             showCodeInDescription,
        //             this.autoCompleteOptions,
        //             null
        //         );
        //     })));
    }

    execAutocomplete(
        term: string,
        _languageCode?: string,
        showCodeInDescription = true,
        basePlainPascalCaseFixedIdentity = null,
        decodeProperties: string[] | null = null,
        searchProperties: string[] | null = null,
        orderByPropertyNames: OrderBy[] | null = null,
        additionalFilters: Filter[] | null = null,
        joinOperator: string | undefined = undefined,
    ): Observable<Array<{ description: string, identity: any, all: any }>> {

        joinOperator = joinOperator ?? this.joinOperator;
        additionalFilters = additionalFilters || this.additionalFilters || [];
        decodeProperties = decodeProperties || this.decodeProperties || this.getMainDescriptionPropertiesList();

        // Rimuovo tra le decode properties quelle custom
        decodeProperties = decodeProperties.filter((d) => {
            return this.externalMetaData.dependentAggregateMetaData.rootMetaData.allPropertyNames.map((a) => a.toLowerCase()).indexOf(d.toLowerCase()) > -1
        })

        searchProperties = searchProperties || this.searchProperties || decodeProperties;
        orderByPropertyNames = orderByPropertyNames || this.orderByPropertyNames || Array.from(new Set(decodeProperties)).map((propertyName: string) => {
            return new OrderBy({ propertyName, sortType: OrderByType.Ascending });
        });

        let autoCompleteOptions = new AutoCompleteExternalOptions();

        // TODO calcolo le additionalOutputProperies in base all eventuale autocompleteProperties
        // Se è stato impostato autocompleteProperties devo aggiungere le property mancanti nell'additionalOutputProperies
        let additionalOutputProperties = this.additionalOutputProperties;
        // if (this.autocompleteProperties?.length > 0) {
        //     additionalOutputProperties
        // }

        autoCompleteOptions.outputProperties = Array.from(new Set([...this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames, ...decodeProperties, ...additionalOutputProperties]));
        autoCompleteOptions.propertySearchList = Array.from(new Set([...this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames, ...searchProperties]));
        autoCompleteOptions.orderByPropertyNames = orderByPropertyNames;

        if (term?.length > 0) {
            const termSplitted = term.split(joinOperator);
            if (showCodeInDescription && termSplitted?.length > 1) {
                term = termSplitted[0];
            }
        }


        autoCompleteOptions.searchValue = term;
        autoCompleteOptions.fullRootModelName = this.domainModelMetaData.fullName;

        // Resetto i filtri
        autoCompleteOptions.additionalFilters = [];

        if (basePlainPascalCaseFixedIdentity) {
            const baseKeys = Object.keys(basePlainPascalCaseFixedIdentity);

            for (const baseKey of baseKeys) {
                const newFilter = new Filter();
                newFilter.name = baseKey;
                newFilter.value = basePlainPascalCaseFixedIdentity[baseKey];
                autoCompleteOptions.additionalFilters.push(newFilter);
            }
        }

        autoCompleteOptions.additionalFilters = [
            ...autoCompleteOptions.additionalFilters,
            ...additionalFilters
        ].filter((existFilter) => existFilter != null);

        const dm = new this.externalDomainModelType();
        this.updateIsActivePropertyVisibility(dm);
        if (dm instanceof OCCAuditDeactivableModel) {
            const prop = 'IsActive';
            if (autoCompleteOptions.outputProperties.indexOf(prop) === -1) {
                autoCompleteOptions.outputProperties.push(prop);
            }
        }

        return this.externalRetriever
            .getExternalAutoCompleteValues(autoCompleteOptions)
            .pipe(map(r => r.result.map((properties: any[]) => {
                return this.parseAutocompleteDataFromPropertyArray(
                    properties,
                    showCodeInDescription,
                    autoCompleteOptions.outputProperties,
                    basePlainPascalCaseFixedIdentity,
                    this.autocompleteProperties?.length > 0 ? this.autocompleteProperties : decodeProperties,
                    joinOperator
                );
            })));
    }

    async setFromModel(value: TModel) {
        this._decodeInProgress = true;
        const oldValue = this.backingFieldCanNotifyModified;
        this.backingFieldCanNotifyModified = true;
        this.setExternalDomainModelBackingFields(value)
        this.backingFieldCanNotifyModified = oldValue;
        setTimeout(() => {
            this._decodeInProgress = false;
            this.decodeCompleted.next();
        })

    }

    async setCodeValue(value: any): Promise<void> {


        if (this.codeProperties.size > 1) {

            const parentPlainPascalCaseIdentity = this.parentPlainIdentity$?.value;
            if (parentPlainPascalCaseIdentity) {
                const keysParentPlainPascalCaseIdentity = Object.keys(parentPlainPascalCaseIdentity);
                for (const parentPlainPascalCaseIdentityKey of keysParentPlainPascalCaseIdentity) {
                    await this.codeProperties.get(MetaDataUtils.toCamelCase(parentPlainPascalCaseIdentityKey)).setValueAsync(parentPlainPascalCaseIdentity[parentPlainPascalCaseIdentityKey])
                }
                for (const [key, code] of this.codeProperties) {
                    if (code.setIdentityOnSet) {

                        if (code.propertyMetaData.getType() === 'String') {
                            value = value?.toString();
                        } else if (code.propertyMetaData.getType() === 'Numeric') {
                            value = (typeof value) === "string" ? parseFloat(value) : value;
                        }

                        await code.setValueAsync(value);
                        break;
                    }
                }
            }
        } else {
            const code = this.codeProperties.values().next().value as PropertyViewModelInterface;

            if (code.propertyMetaData.getType() === 'String') {
                value = value?.toString();
            } else if (code.propertyMetaData.getType() === 'Numeric') {
                value = (typeof value) === "string" ? parseFloat(value) : value;
            }
            await code.setValueAsync(value);
        }
        // return new Promise(async (resolve) => {

        //     for (const [key, code] of this.codeProperties) {
        //         const oldValue = code.canNotifyModified;
        //         code.canNotifyModified = this.backingFieldCanNotifyModified;
        //         await code.setValueAsync(value);
        //         code.canNotifyModified = oldValue;
        //     }

        //     if (this.identityIsValid(this.getIdengetPlainPascalCaseIdentityFromCodeProperties()) && this.decodeInProgress) {

        //         await firstValueFrom(
        //             merge(this.externalDomainModelChanged, this.onErrorStatusChanged)
        //                 .pipe(takeUntil(this.destroy$))
        //         );
        //         resolve();
        //     } else {
        //         resolve();
        //     }
        // });
    }

    /**
     * Ritorna la descrizione corrente
     * @returns
     */
    getDescription(
        showCodeInDescription?: boolean,
        decodeProperties: string[] | null = null,
        joinOperator: string | undefined = undefined
    ): string {

        joinOperator = joinOperator ?? this.joinOperator;

        // Recupero la lista prima dalla decodeProperties altrimenti da quella della classe ed infine dai metadati (getMainDescriptionPropertiesList)
        decodeProperties = decodeProperties || this.decodeProperties || this.getMainDescriptionPropertiesList();

        if (showCodeInDescription) {
            if (this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames?.length > 1) {

                for (const [key, code] of this.codeProperties) {
                    if (code.setIdentityOnSet) {

                        const properties = decodeProperties.map(
                            (p) => {
                                // Se esiste il pvm non è custom
                                if (this.getProperty(MetaDataUtils.toCamelCase(p))) {
                                    return this.getProperty(MetaDataUtils.toCamelCase(p))?.formattedValue;
                                } else {
                                    // se non è esiste è custom
                                    return this[MetaDataUtils.toCamelCase(p)]?.value?.toString()
                                }
                            }
                        )

                        const description = [
                            code.value?.toString(),
                            ...properties
                        ]
                            .filter((p) => p != null && p?.length > 0)
                            .join(joinOperator);
                        return description?.length > 0 ? description : null;
                    }
                }
                return 'setIdentityOnSet non impostato correttamente in ' + this.externalMetaData.principalPropertyName
            } else {
                const code = this.codeProperties.values().next().value;

                const properties = decodeProperties.map(
                    (p) => {
                        // Se esiste il pvm non è custom
                        if (this.getProperty(MetaDataUtils.toCamelCase(p))) {
                            return this.getProperty(MetaDataUtils.toCamelCase(p))?.formattedValue;
                        } else {
                            // se non è esiste è custom
                            return this[MetaDataUtils.toCamelCase(p)]?.value?.toString()
                        }
                    }
                )

                const description = [
                    code.value?.toString(),
                    ...properties
                ]
                    .filter((p) => p != null && p?.length > 0)
                    .join(joinOperator);
                return description?.length > 0 ? description : null;
            }
        } else {

            const properties = decodeProperties.map(
                (p) => {
                    // Se esiste il pvm non è custom
                    if (this.getProperty(MetaDataUtils.toCamelCase(p))) {
                        return this.getProperty(MetaDataUtils.toCamelCase(p))?.formattedValue;
                    } else {
                        // se non è esiste è custom
                        return this[MetaDataUtils.toCamelCase(p)]?.value?.toString()
                    }
                }
            )

            const description = [
                ...properties
            ]
                .filter((p) => p != null && p?.length > 0)
                .join(joinOperator);
            return description?.length > 0 ? description : null;
        }
    }

    getCurrentPlainIdentityFromDomainModel(): { [key: string]: string | number; } {
        if (this.domainModel) {
            const identityObject = {};
            for (const [key, pvm] of this.codeProperties) {
                identityObject[key] = this.domainModel.getPropertyValue(MetaDataUtils.toCamelCase(key));
            }
            return identityObject;
        }
        return null;
    }

    areIdentityValuesEqual(
        identity1: { [key: string]: string | number; },
        identity2: { [key: string]: string | number; }
    ) {

        if (identity1 == null && identity2 == null) {
            return true;
        }

        if (identity1 == null && identity2 != null) {
            return false;
        }

        if (identity1 != null && identity2 == null) {
            return false;
        }

        const identity1Keys = Object.keys(identity1);
        const identity2Keys = Object.keys(identity2);

        const sameLenghtKeys = identity1Keys?.length === identity2Keys?.length;
        if (!sameLenghtKeys) {
            return false;
        }

        for (const identity1Key of identity1Keys) {
            const foundKeyInIdentity2 = identity2Keys.map((k) => k.toLowerCase()).indexOf(identity1Key.toLowerCase())
            if (foundKeyInIdentity2 == -1) {
                return false;
            }
            if (identity1[identity1Key] !== identity2[identity2Keys[foundKeyInIdentity2]]) {
                return false;
            }
        }
        return true
    }

    async setPlainPascalCaseIdentity(identity: { [key: string]: string | number; }): Promise<void> {
        if (!this.areIdentityValuesEqual(identity, this.getCurrentPlainIdentityFromDomainModel())) {
            this.execDecode(identity);
            await firstValueFrom(merge(
                this.decodeCompleted,
                this.onErrorStatusChanged
            ).pipe(timeout(50000), take(1))).catch(() => {
                LogService.log('ATTENZIONE: la decode non ha finito in 50000ms o è fallita!')
            });
        } else {
            if (identity == null) {
                this.reset();
            }
        }
    }

    postZoom() { }

    async execDecode(identity: { [key: string]: string | number; }) {
        this.execDecodeEmitter.next(identity);
    }

    async execDecodeHandler(identity: { [key: string]: string | number; }) {

        (this.externalRetriever as CoreOrchestratorViewModelInterface).notifyPendingAutocompleteStarting();

        if (this.identityIsValid(identity)) {
            const domainModel = await this.retrieveDomainModelByIdentity(identity);
            this._decodeInProgress = false;
            // if (domainModel && domainModel['mockName'].indexOf('ok') !== -1) {
            if (domainModel) {
                this.setExternalDomainModelBackingFields(domainModel);
                // this.getDomainModel().setPropertyValue(this.propertyName, domainModel);
            } else {

                // In questo caso non è stata trovata alcun domain model, quindi: setto l'errore su tutti i campi di relazione
                // NOTA: l'errore in questo caso viene messo con chiave "Decode" anzichè "Value"; il caso "Value" infatti
                // viene usato per gli errori di validazione standard ma siccome la validazione standard delle property non
                // sa fare la decodifica, gli errori di decodifica vanno gestiti separatamente
                // Inoltre setto hasDecodError=true che serve per disabilitare alcune funzionalità della toolbar
                const model = new this._externalDomainModelType();

                this.codeProperties.forEach((code) => {

                    if (code.setIdentityOnSet) {
                        // const arr = new Array<CodeValueMessageArg>();
                        // const arg = new CodeValueMessageArg();
                        // arg.code = MessageCodes.ErrorCodeNotFound_TAG_NomeCampo;
                        // arg.value = code.metadataShortDescription;
                        // arr.push(arg);
                        // const errorMessage = MessageResourceManager.Current.getMessageWithArgs(MessageCodes.ErrorCodeNotFound, arr);
                        // this.codeProperties.get(code.propertyName).setErrors(SourceMessage.ValidationDecode, [errorMessage]);
                        model.setPropertyValue(code.propertyName, this.getProperty(code.propertyName).getValue());
                        // Devo impostare anche i backing field
                        const associationMetaData = this.externalMetaData.associationProperties.find((ass) =>
                            MetaDataUtils.toCamelCase(ass.dependentPropertyName) === code.propertyName
                        );
                        const backingFieldPropertyName = MetaDataUtils.toCamelCase(associationMetaData.principalPropertyName);
                        const backingField = (this.parent as ViewModelInterface).getProperty(backingFieldPropertyName);

                        let oldValue = backingField.canNotifyModified;
                        backingField.canNotifyModified = this.backingFieldCanNotifyModified;
                        backingField.setValue(this.getProperty(code.propertyName).getValue());
                        // backingField.setErrors(SourceMessage.ValidationDecode, [errorMessage]);
                        backingField.canNotifyModified = oldValue;

                        oldValue = code.canNotifyModified;
                        code.canNotifyModified = this.backingFieldCanNotifyModified;
                        code.setModel(model);
                        code.canNotifyModified = oldValue;
                    }

                });
                this.descriptionProperties.forEach((description) => {
                    const oldValue = description.canNotifyModified;
                    description.canNotifyModified = this.backingFieldCanNotifyModified;
                    description.setModel(model);
                    description.canNotifyModified = oldValue;
                });

                this.propertyViewModels.forEach(pvm => {
                    pvm.decodePending = false;
                });

                this._hasDecodeError = true;
                this.domainModel = null;
                const args = new ExternalDomainModelChangedEventArgs();
                args.domainModel = this.domainModel;
                args.senderName = this._propertyName;
                this.externalDomainModelChanged.emit(args);

                this.updateCurrentErrors();
                this.onErrorStatusChanged.next();
            }

        } else {
            this.propertyViewModels.forEach(pvm => {
                pvm.decodePending = false;
            });
            this.reset();
        }
        this.decodeCompleted.emit();
        (this.externalRetriever as CoreOrchestratorViewModelInterface).notifyPendingAutocompleteEnded();
    }

    reset() {
        // il campo codice è stato "sbiancato": in tal caso non richiamo
        // nemmeno la decodifica, ma effettuo queste operazioni:
        // - setto a null il domain model
        // - setto l'errore su tutti i campi di relazione
        this._hasDecodeError = false;
        this.clearErrors();
        this.codeProperties.forEach((pvm) => {
            if (pvm.setIdentityOnSet) {
                pvm.clearErrors();
            }
        });

        // TODO Tommy verificare in decoratore se l'external è required
        // this.codeProperties.forEach((pvm) => {
        //     // tolgo eventuale errore di decodifica
        //     pvm.setErrors(SourceMessage.ValidationDecode, new Array<string>());
        //     // aggiungo errore di validazione
        //     const error = new BaseError();
        //     error.message = `RequiredFailed ${pvm.metadataShortDescription}`;
        //     error.propertyName = this._propertyName;
        //     error.code = 'RequiredFailed';

        //     pvm.addError(SourceMessage.ValidationDecode, error);
        // });
        this.setCurrentDomainModel(null);
        this.resettedData.next()
    }

    setAutomaticAutocomplete() {
        this.autocomplete = this.isAutocompleteEnable();
        this.autoCompleteOptions = new AutoCompleteExternalOptions();
        this.autoCompleteOptions.propertySearchList = [...this.getCodePropertiesList(), ...this.getMainDescriptionPropertiesList()];
        this.autoCompleteOptions.orderByPropertyNames = this.getMainDescriptionPropertiesList().map((propertyName: string) => {
            return new OrderBy({ propertyName, sortType: OrderByType.Ascending });
        });
        this.autoCompleteOptions.outputProperties = [...this.getCodePropertiesList(), ...this.getMainDescriptionPropertiesList()];

        this.applyCustomAutomaticAutocompleteLogic();
    }

    protected override internalIsRequired = false;

    override set isRequired(value: boolean) {
        if (this.internalIsRequired !== value) {
            this.internalIsRequired = value;
            this.validate();
            this.onPropertyChanged('isRequired');
        }
    }

    override get isRequired(): boolean {
        return this.internalIsRequired;
    }

    async initExternalViewModel(
        externaDomainModel: TModel,
        _aggregateMetaData: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        externalMetaData: ExternalMetaData,
        path: string,
        isMockedViewModel = false,
        externaDomainModelType: ClassType<TModel> = null,
        overrideDecoratorData: ExternalMetaData = null,
        processedNulledModel = []
    ): Promise<void> {

        // TODO se è impostato nel decoratore prendere quel valore
        this.internalIsRequired = overrideDecoratorData?.isRequired != null ? overrideDecoratorData?.isRequired : externalMetaData.isRequired;
        this.externalMetaData = externalMetaData;
        this._securityAccess = externalMetaData?.userMetaData?.securityAccess;
        this.reservedPath = path;
        this.reservedName = MetaDataUtils.toCamelCase(externalMetaData.principalPropertyName);
        this.isMock = isMockedViewModel;

        await this.initAggregateElementViewModel(
            externaDomainModel as TModel,
            externalMetaData.dependentAggregateMetaData,
            orchestratorViewModel,
            externalMetaData.dependentAggregateMetaData.rootName,
            this.isMock,
            externaDomainModelType,
            processedNulledModel
        );

        this.domainModelFullName = externalMetaData.dependentAggregateMetaData.rootFullName;

        if (overrideDecoratorData?.descriptions?.displayName?.length > 0 || overrideDecoratorData?.descriptions?.displayNameKey?.length > 0) {
            this.metadataShortDescription = overrideDecoratorData.descriptions.displayName?.length > 0 ? overrideDecoratorData.descriptions.displayName : MessageResourceManager.Current.getMessage(overrideDecoratorData.descriptions.displayNameKey);
            this.metadataDescription = overrideDecoratorData.descriptions.description?.length > 0 ? overrideDecoratorData.descriptions.description : MessageResourceManager.Current.getMessage(overrideDecoratorData.descriptions.descriptionKey);
        } else {
            this.metadataShortDescription = externalMetaData.descriptions.displayName?.length > 0 ? externalMetaData.descriptions.displayName : MessageResourceManager.Current.getMessage(externalMetaData.descriptions.displayNameKey);
            this.metadataDescription = externalMetaData.descriptions.description?.length > 0 ? externalMetaData.descriptions.description : MessageResourceManager.Current.getMessage(externalMetaData.descriptions.descriptionKey);
        }

        this._propertyName = MetaDataUtils.toCamelCase(externalMetaData.principalPropertyName);

        // Creo le lista dei campi chiave e description
        this._codeProperties = this.buildCodeProperties(externalMetaData);

        // TODO Tommy
        // mi serve l'domain model metadata dell'entità principale
        // let isIdentityPrincipalAssciationProperty = false;
        // const parentDomainModelMetaData = MetaDataUtils.getParentDomainModelMetaData(aggregateMetaData, externalRelationMetadata);
        // if (parentDomainModelMetaData !== undefined) {
        //     // determino se l'external view model sottende una chiave primaria del domain model principale
        //     isIdentityPrincipalAssciationProperty = MetaDataUtils.isIdentityPrincipalAssciationProperty(
        //    parentDomainModelMetaData, externalRelationMetadata);
        // }

        this.propertyViewModels.forEach(pvm => {
            if (this._codeProperties.has(pvm.propertyName)) {
                //  setto sui campi chiave la Description e ShortDescription prendendoli dalla externalRelation
                pvm.metadataDescription = MessageResourceManager.Current.getMessage(externalMetaData.descriptions.descriptionKey);
                pvm.metadataShortDescription = MessageResourceManager.Current.getMessage(externalMetaData.descriptions.descriptionKey);
                pvm.setCodeOnExternal(true);

                // TODO Tommy
                // if (isIdentityPrincipalAssciationProperty) {
                //     pvm.isInternalKey = true;
                // }
            } else {
                this._descriptionProperties.push(pvm);

                pvm.setCodeOnExternal(false);

                if (this.externalMetaData.cloneType !== CloneTypes.LocalReplica) {
                    // Le property non code le disabilito
                    pvm.isEnabled = false;
                } else {
                    if (this.domainModel?.currentState === DomainModelState.New) {
                        pvm.propertyChanged.subscribe(() => {
                            if (this.decodeInProgress == false) {
                                this.localReplicaAutocompleteDataRequested.next(this.domainModel);
                            }
                        })
                    }
                }
            }
        });

        if (this.externalMetaData.cloneType == CloneTypes.LocalReplica) {
            this.localReplicaAutocompleteDataRequested.pipe(debounceTime(1000)).subscribe((dm) => {
                this.externalRetriever.localReplicaAutocomplete(dm)
            })
        }
    }

    setFormModel() {

    }

    isAutocompleteEnable() {
        return true;
    }

    getMainDescriptionPropertiesList(): Array<string> {
        const mainDescriptionsProperties = this.externalMetaData.dependentAggregateMetaData.rootMetaData.strings.filter((metaData) =>
            metaData.isMainDescription === true
        );

        if (mainDescriptionsProperties.length === 0) {
            LogService.warn(`ATTENZIONE non è stata impostata alcuna MainDescription nelle strings dell'oggetto ${this.externalMetaData.dependentAggregateMetaData.rootMetaData.fullName}!`);

            // Utilizzo la prima stringa come fallback
            let fallbackPropertyName = this.externalMetaData.dependentAggregateMetaData.rootMetaData.strings[0].name;

            if (this.externalMetaData.dependentAggregateMetaData.rootMetaData.strings.length > 1) {

                if (
                    this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames
                        .indexOf(this.externalMetaData.dependentAggregateMetaData.rootMetaData.strings[0].name) > -1
                ) {
                    // Se la prima string è un identity uso la seconda
                    fallbackPropertyName = this.externalMetaData.dependentAggregateMetaData.rootMetaData.strings[1].name;
                }

            }

            LogService.warn(`Utilizzo ${fallbackPropertyName} come fallback per la main description!`);
            return [fallbackPropertyName];


        } else {
            return mainDescriptionsProperties.map((meta) => meta.name);
        }
    }

    getCodePropertiesList(): Array<string> {
        return this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames;
    }

    async startPresentation(
        _codePropertyViewModel: PropertyViewModelInterface,
        newTab = false,
        showRead = true,
        additionalQueryParams = new URLSearchParams(),
        externalReturn = true
    ) {

        await PresentationCache.addIfNotExist(this.domainModelFullName);
        const resultBaseUrl = PresentationCache.get(this.domainModelFullName);

        if (resultBaseUrl != null && resultBaseUrl !== '') {

            let jsonIdentity = '{}';

            if (showRead) {
                const plainIdentity = this.getPlainCamelCaseIdentityFromCodeProperties();
                jsonIdentity = JSON.stringify(plainIdentity);
            }

            this.eventDispatcher.externalModalExecuted.emit();

            if (newTab) {
                if (externalReturn) {
                    UIStarter.startClientAndReturn(resultBaseUrl, (payload: Message<string>) => {
                        this.f8Return(payload.data);
                    }, (errorMessage: string) => {
                        this.eventDispatcher.externalModalReturned.emit();
                        throw new Error(errorMessage);
                    }, jsonIdentity, additionalQueryParams);
                } else {
                    UIStarter.startClientWithoutReturn(resultBaseUrl, (errorMessage: string) => {
                        this.eventDispatcher.externalModalReturned.emit();
                        throw new Error(errorMessage);
                    }, jsonIdentity, additionalQueryParams);
                }
            } else {
                const result = await this.externalRetriever.showExternalModalWithResultAsync<string>(
                    resultBaseUrl,
                    jsonIdentity,
                    additionalQueryParams,
                    this.metadataDescription,
                    externalReturn,
                );
                if (result.cancel) {
                    this.eventDispatcher.externalModalReturned.emit();
                    this.externalPresentationCompleted.emit();
                } else {
                    if (externalReturn) {
                        this.f8Return(result.result);
                    }
                }
            }

        } else {

            // TODO: Notificare che non esiste la gestione del related

        }

    }

    gotFocus(codePropertyViewModel: PropertyViewModelInterface) {
        if (this.eventDispatcher?.externalViewModelFocused) {
            this.eventDispatcher.externalViewModelFocused.emit(codePropertyViewModel);
        }

    }

    lostFocus() {
        if (this.eventDispatcher?.externalViewModelFocused) {
            this.eventDispatcher.externalViewModelFocused.emit(null);
        }
    }

    async checkAndSetParentIdentity() {
        if (this.externalMetaData.parentIdentityPropertyPathName?.length && this.externalMetaData.parentIdentityPropertyPathName?.length > 0) {

            const parentIdentity = await this.externalRetriever.getParentIdentityByPathName(this.externalMetaData.parentIdentityPropertyPathName)

            if (parentIdentity) {
                this.parentIdentity$.next(parentIdentity);
                this.fixCodePropertiesBasedOnParentIdentity();
            } else {
                LogService.warn(`Non è stato trovato il parent identity di ${this.externalMetaData.principalPropertyName} utilizzando come parentIdentityPropertyPathName ${this.externalMetaData.parentIdentityPropertyPathName}`);
            }
        }
    }

    setBasePlainIdentityFromNumericParent() {
        if (this.parentIdentity$?.value && (this.parentIdentity$.value instanceof NumericPropertyViewModel || this.parentIdentity$.value instanceof NNumericPropertyViewModel)) {
            const pvm: PropertyViewModelInterface = this.parentIdentity$.value;

            let parentPlainIdentity = {};

            // recupero la fixed identity dal parent identity
            const localAssociation = this.externalMetaData.associationProperties.find((a) => a.principalPropertyName === pvm.propertyMetaData.name);
            if (localAssociation) {


                parentPlainIdentity[localAssociation.dependentPropertyName] = pvm.value;

                const codeProperty = this.codeProperties.get(MetaDataUtils.toCamelCase(localAssociation.dependentPropertyName));
                codeProperty.setIdentityOnSet = false;
            }

            if (JSON.stringify(parentPlainIdentity) == '{}') {
                parentPlainIdentity = null;
            }

            this.parentPlainIdentity$.next(parentPlainIdentity);
        }
    }

    setBasePlainIdentityFromExternalParent(): void {
        if (this.parentIdentity$?.value && this.parentIdentity$.value instanceof ExternalViewModel) {
            let parentPlainIdentity = {};

            // recupero la fixed identity dal parent identity
            for (const parentAssociation of this.parentIdentity$.value.externalMetaData.associationProperties) {
                const localAssociation = this.externalMetaData.associationProperties.find((a) => a.principalPropertyName === parentAssociation.principalPropertyName);

                const parentPlainPascalCaseIdentityFromCodeProperties = this.parentIdentity$.value.getPlainPascalCaseIdentityFromCodeProperties();
                if (parentPlainPascalCaseIdentityFromCodeProperties) {
                    parentPlainIdentity[localAssociation.dependentPropertyName] = parentPlainPascalCaseIdentityFromCodeProperties[parentAssociation.dependentPropertyName];
                }

                // Imposto anche la property setIdentityOnSet a false in quelle code property
                const codeProperty = this.codeProperties.get(MetaDataUtils.toCamelCase(localAssociation.dependentPropertyName));
                codeProperty.setIdentityOnSet = false;
            }

            if (JSON.stringify(parentPlainIdentity) == '{}') {
                parentPlainIdentity = null;
            }

            this.parentPlainIdentity$.next(parentPlainIdentity);
        }
    }

    fixCodePropertiesBasedOnParentIdentity() {
        if (this.parentIdentity$?.value && this.parentIdentity$.value instanceof ExternalViewModel) {

            this.setBasePlainIdentityFromExternalParent();

            // TODO Verificare che ad ogni postInit viene cancellato il subscribe3
            this.parentIdentity$.value.externalDomainModelChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe((_arg) => {
                this.setBasePlainIdentityFromExternalParent();
                // if (arg?.domainModel == null) {
                this.setPlainPascalCaseIdentity(null);
                // }
            })
        } else if (this.parentIdentity$?.value && (this.parentIdentity$.value instanceof NumericPropertyViewModel || this.parentIdentity$.value instanceof NNumericPropertyViewModel)) {
            this.setBasePlainIdentityFromNumericParent();

            // TODO Verificare che ad ogni postInit viene cancellato il subscribe
            (this.parentIdentity$.value as PropertyViewModelInterface).propertyChanged.pipe(takeUntil(this.destroySubscribers$)).subscribe((arg) => {
                this.setBasePlainIdentityFromNumericParent();
                if (arg?.propertyName == (this.parentIdentity$.value as PropertyViewModelInterface).bindedValuePropertyName) {
                    this.setPlainPascalCaseIdentity(null);
                }
            })
        }
    }
    //#endregion PUBLIC METHODS

    //#region PROTECTED METHODS
    protected override getInternalViewModelType(propertyName: string): any {
        return InternalViewModelTypeInspector.getValue(this, propertyName);
    }

    protected async checkAndSetCustomParentIdentity() {
    }

    protected override async buildInternalViewModels(isMockedViewModel = false) {

        for (const internalRelation of this.domainModelMetaData.internalRelations) {

            // TODO
            // //Verifico se questa internalRelation è stata esposta nel ViewModel e se esiste l'entity nell'aggregato
            // if (Properties.ContainsKey(internalRelation.PropertyName) &&
            //         entityProperties.ContainsKey(internalRelation.PropertyName))

            // PATCH PER camelCase DEVE ESSERE PascalCase
            const propertyName = MetaDataUtils.toCamelCase(internalRelation.principalPropertyName);

            const internalModel: ModelInterface = this.domainModel[propertyName];
            if (internalModel != null) {

                // Recupero il tipo del modello dell'internal dal decoratore @Type
                let internalModelType = null
                const typeMetadata: TypeMetadata = defaultMetadataStorage.findTypeMetadata(this.domainModelType, propertyName);
                if (typeMetadata && typeMetadata.typeFunction({
                    newObject: new this.domainModelType()
                } as any)) {
                    internalModelType = typeMetadata.typeFunction({
                        newObject: new this.domainModelType()
                    } as TypeHelpOptions) as ClassType<any>
                }

                const internalViewModelType = this.getInternalViewModelType(propertyName);
                if (internalViewModelType != null) {
                    const ivm = await ViewModelFactory.createInternalViewModel(
                        internalViewModelType,
                        internalModel,
                        this.aggregateMetaData,
                        this.modifiedSubscriber as CoreOrchestratorViewModelInterface,
                        internalRelation,
                        false,
                        this.reservedPath.length > 0 ? (this.reservedPath + '.' + this.reservedName) : this.reservedName,
                        isMockedViewModel,
                        this,
                        internalModelType
                    );
                    this[propertyName] = ivm;
                    this.relationViewModels.set(propertyName, ivm as ViewModelInterface);

                    ivm.viewModelChanged.subscribe(_e => {
                        this._viewModelChangeDebouncer.next();
                    });
                } else {
                    // throw new InvalidOperationException
                    // ("In the class '" + this.GetType().Name + "' the type of property '" + internalRelation.PropertyName + "' must be descendant of 'InternalViewModel'");

                    // TODO: Da riattivare dopo il controllo di esistenza della prop nel viewmodel
                    // throw new Error('INVALID PROPERTY');
                    LogService.warn(`Campo '${propertyName}' non trovato nel view model ${this.constructor.name}`);
                }

            } else {
                // TODO: la entity è null, valutare cosa fare
                // Credo che la entity abbia un metodo per creare una entity vuota, di default:
                // la cosa giusta è forse creare un ViewModel passando quella di default
            }

        }

        // TODO: resta il caso ManyToMany: non so come va trattato
    }

    // Faccio l'override per disabilitare questo comportamento nelle external
    protected override disableIdentities() {

    }

    protected createZoomUIStarterArgs(_metadata: DomainModelMetaData, zoomOption: ZoomAdvancedOptions): ZoomUIStarterArgs {
        const callerAggregateMetaData = (this.parent as ViewModelInterface).aggregateMetaData;
        const externalMetaData = (this.parent as ViewModelInterface).domainModelMetaData.externals.find(
            (extMetaData: ExternalMetaData) =>
                MetaDataUtils.toCamelCase(extMetaData.principalPropertyName) === this.propertyName
        );

        return new ZoomUIStarterArgs(
            this.aggregateMetaData,
            this.domainModelName,
            this.domainModelFullName,
            externalMetaData.isRemote,
            callerAggregateMetaData,
            callerAggregateMetaData.rootName,
            zoomOption
        );
    }

    protected createLockedFilters(_keyPvm: PropertyViewModelInterface, _descriptionPvm: PropertyViewModelInterface[]): Filter[] {
        const lockedFilters: Filter[] = [];

        if (this.parentIdentity$.value != null && this.parentIdentity$.value instanceof ExternalViewModel) {

            const extVm: ExternalViewModel<any, any> = (this.parentIdentity$.value as ExternalViewModel<any, any>);

            const identityToUse = extVm.getPlainPascalCaseIdentityFromCodeProperties();
            for (const parentAssociation of extVm.externalMetaData.associationProperties) {
                const parentFilter = new Filter();
                const association = this.externalMetaData.associationProperties.find((a) => a.principalPropertyName === parentAssociation.principalPropertyName)
                if (association) {
                    parentFilter.name = association.dependentPropertyName;
                    parentFilter.operator = FilterOperators.Equals;
                    parentFilter.value = identityToUse[parentAssociation.dependentPropertyName];
                    parentFilter.isLocked = true;
                    lockedFilters.push(parentFilter);
                }

            }
        } else if (this.parentIdentity$.value != null && (this.parentIdentity$.value instanceof NumericPropertyViewModel || this.parentIdentity$.value instanceof NNumericPropertyViewModel)) {
            const pvm: NumericPropertyViewModel | NNumericPropertyViewModel = (this.parentIdentity$.value as NumericPropertyViewModel);
            const association = this.externalMetaData.associationProperties.find((a) => a.principalPropertyName === pvm.propertyMetaData.name);
            if (association) {
                const parentFilter = new Filter();
                parentFilter.name = association.dependentPropertyName;
                parentFilter.operator = FilterOperators.Equals;
                parentFilter.value = pvm.value;
                parentFilter.isLocked = true;
                lockedFilters.push(parentFilter);
            }
        }
        return lockedFilters;
    }

    protected createAutomaticFilters(_keyPvm: PropertyViewModelInterface, _descriptionPvm: PropertyViewModelInterface[]): Filter[] {
        // Imposto come filtro la chiave dello zoom; se ho un valore allora metto anche il valore altrimenti metto solo l'operatore
        const automaticFilters: Filter[] = [];
        // const keyFilter = new Filter();
        // const keyPvmType = keyPvm.domainModelMetadata.getPropertyMetaData(keyPvm.propertyName).getType();
        // keyFilter.operator = keyPvmType === 'String' ?
        //     FilterOperators.StartsWith : (keyPvmType === 'Numeric' ? FilterOperators.GreaterOrEqualThan : FilterOperators.Equals);
        // TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
        // keyFilter.name = MetaDataUtils.toPascalCase(keyPvm.propertyName);
        // if (keyPvm != null && keyPvm.getValue() != null && keyPvm.getModel() != null) {
        //     keyFilter.value = keyPvm.getValue();
        // }
        // automaticFilters.push(keyFilter);

        // // Come filtri successivi aggiungo tutte le descriptions;
        // // se ho un valore allora metto anche il valore altrimenti metto solo l'operatore
        // if (descriptionPvm.length > 0) {
        //     descriptionPvm.forEach((pvm) => {
        //         const descFilter = new Filter();
        //         const pvmType = pvm.domainModelMetadata.getPropertyMetaData(pvm.propertyName).getType();
        //         descFilter.operator = pvmType === 'String' ?
        //             FilterOperators.StartsWith : (pvmType === 'Numeric' ? FilterOperators.GreaterOrEqualThan : FilterOperators.Equals);
        //         TODO: ATTENZIONE toPascalCase non è simmetrico a CamelCase
        //         descFilter.name = MetaDataUtils.toPascalCase(pvm.propertyName);
        //         if (pvm != null && pvm.getValue() != null) {
        //             descFilter.value = pvm.getValue();
        //         }
        //         automaticFilters.push(descFilter);
        //     });
        // }

        // // se ho inserito un testo nell'autocomplete ma non è stato trovato, lo ripropongo nel main description
        // if (keyPvm.getModel() == null && keyPvm.getValue() != null) {
        //     const mainDescriptionFilter = automaticFilters.find(
        //         (f) => f.name === keyPvm.domainModelMetadata.getMainDescriptionProperty().name
        //     );
        //     if (mainDescriptionFilter) {
        //         mainDescriptionFilter.value = keyPvm.getValue();
        //     }
        // }
        return automaticFilters;
    }

    protected updateIsActivePropertyVisibility(dm) {
        this._hideIsActiveField = dm instanceof OCCAuditDeactivableModel;
    }


    getIdentityFromProperties(
        properties: Array<string | number>,
        outputProperties: string[]
    ): { [key: string]: string | number } {

        const identityObject = {};

        // in output properties devono esistere i campi dell'identity
        const identityNames = this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames;

        for (const identityName of identityNames) {
            const outputIndex = outputProperties.indexOf(identityName)
            if (outputIndex > -1) {
                identityObject[identityName] = properties[outputIndex];
            } else {
                LogService.warn(`Verifica di aver impostato correttamente l'outputProperties di ${this.externalMetaData.principalPropertyName}: il campo dell'identity ${identityName} non è stato trovato!`)
            }

        }

        return identityObject;

    }

    protected parseAutocompleteDataFromPropertyArray(
        properties: Array<string | number>,
        showCodeInDescription: boolean = true,
        outputProperties: string[],
        basePlainPascalCaseFixedIdentity: { [key: string]: string | number } | null,
        decodeProperties: string[] = null,
        joinOperator: string | undefined = undefined
    ): { description: string, identity: { [key: string]: string | number }, all: any } {
        // TODO Tommy gestire meglio la lista degli input properties
        // il primo elemento deve essere l'id
        const all = outputProperties.map((key, index) => {
            return {
                [key]: properties[index]
            };
        })

        return {
            identity: this.getIdentityFromProperties(properties, outputProperties),
            description: this.getDescriptionFromProperties(
                properties,
                showCodeInDescription,
                outputProperties,
                basePlainPascalCaseFixedIdentity,
                decodeProperties,
                joinOperator
            ),
            all
        };
    }

    //   private getDescriptionFromProperties(properties: any[], showCodeInDescription = true): string {

    //     return this.autoCompleteOptions.outputProperties.map((currentPropertyNameInPascalCase, index) => {
    //         // Nasconde il campo isActive nella descrizione se è stato impostato this._hideIsActiveField
    //         if (currentPropertyNameInPascalCase === 'IsActive' && this._hideIsActiveField) {
    //             return null;
    //         }

    //         if (!showCodeInDescription) {
    //             if (this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames.indexOf(currentPropertyNameInPascalCase) > -1) {
    //                 return null;
    //             }
    //         }
    //         const currentPropertyNameInCamelCase = MetaDataUtils.toCamelCase(currentPropertyNameInPascalCase);
    //         const metaData = this.externalMetaData.dependentAggregateMetaData.rootMetaData.enums.find((en) =>
    //             en.name === currentPropertyNameInPascalCase
    //         );

    //         let formattedValue = properties[index];

    //         if (metaData) {
    //             const enumResource = metaData.valuesResource.find((e) => e.enumValue === formattedValue);
    //             if (!enumResource) {
    //                 return null;
    //             } else {
    //                 formattedValue = MessageResourceManager.Current.getMessage(enumResource.resourceKey);
    //             }
    //         }
    //         return formattedValue;
    //     }).filter(item => item).join(' - ');

    // }

    protected getDescriptionFromProperties(
        properties: Array<string | number>,
        showCodeInDescription = true,
        outputProperties: string[],
        basePlainPascalCaseFixedIdentity: { [key: string]: string | number } | null,
        decodeProperties: string[] = null,
        joinOperator: string | undefined = undefined
    ): string {

        joinOperator = joinOperator ?? this.joinOperator;

        // Se esistono le decodeProperties utilizzo quelle per costruire la description
        if (decodeProperties?.length > 0) {

            const arrDescription = [];

            if (showCodeInDescription) {
                for (const identityName of this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames) {
                    const outputPropertyIndex = outputProperties.indexOf(identityName);
                    // Visualizzo solo i codici di riferimento valorizzati con setIdentityOnSet a true
                    if (outputPropertyIndex > -1 && (this.getProperty(MetaDataUtils.toCamelCase(identityName)) as PropertyViewModel<any>).setIdentityOnSet === true) {
                        arrDescription.push(properties[outputPropertyIndex]);
                    }
                }
            }

            for (const decodeProperty of decodeProperties) {
                const outputPropertyIndex = outputProperties.indexOf(decodeProperty);
                if (outputPropertyIndex > -1) {

                    const metaData = this.externalMetaData.dependentAggregateMetaData.rootMetaData.enums.find((en) =>
                        en.name === decodeProperty
                    );

                    let formattedValue = properties[outputPropertyIndex];

                    if (metaData) {
                        const enumResource = metaData.valuesResource.find((e) => e.enumValue === formattedValue);
                        if (!enumResource) {
                            return null;
                        } else {
                            formattedValue = MessageResourceManager.Current.getMessage(enumResource.resourceKey);
                        }
                    }

                    if (formattedValue != null) {
                      arrDescription.push(formattedValue);
                    }
                }
            }

            return arrDescription.join(joinOperator);
        }

        // Se non esistono le decode properties utilizzo il codice legacy
        return outputProperties.map((currentPropertyNameInPascalCase, index) => {
            // Nasconde il campo isActive nella descrizione se è stato impostato this._hideIsActiveField
            if (currentPropertyNameInPascalCase === 'IsActive') {
                return null;
            }

            // Nascondo le property del basePlainPascalCaseFixedIdentity
            if (basePlainPascalCaseFixedIdentity && currentPropertyNameInPascalCase in basePlainPascalCaseFixedIdentity) {
                return null;
            }

            if (!showCodeInDescription) {
                if (this.externalMetaData.dependentAggregateMetaData.rootMetaData.identityNames.indexOf(currentPropertyNameInPascalCase) > -1) {
                    return null;
                }
            }
            const metaData = this.externalMetaData.dependentAggregateMetaData.rootMetaData.enums.find((en) =>
                en.name === currentPropertyNameInPascalCase
            );

            let formattedValue = properties[index];

            if (metaData) {
                const enumResource = metaData.valuesResource.find((e) => e.enumValue === formattedValue);
                if (!enumResource) {
                    return null;
                } else {
                    formattedValue = MessageResourceManager.Current.getMessage(enumResource.resourceKey);
                }
            }
            return formattedValue;
        }).filter(item => item).join(' - ');

    }

    protected getCodeProperties(): Map<string, PropertyViewModelInterface> {
        return null;
    }

    protected createExternalIdentityByIdentity(identity: Object): TIdentity {
        const externalIdentity: TIdentity = new this._identityType();

        if (identity != null) {

            const identityKeys = Object.keys(identity);

            for (const identityKey of identityKeys) {
                externalIdentity.setPropertyValue(MetaDataUtils.toCamelCase(identityKey), identity[identityKey]);
            }
        }

        return externalIdentity;
    }

    protected async retrieveDomainModelByIdentity(identity: Object): Promise<TModel> {
        const externalIdentity: TIdentity = this.createExternalIdentityByIdentity(identity);
        const domainModelName = this._externalDomainModelTypeName;
        const externalDomainModelType = this._externalDomainModelType;

        return await this.externalRetriever.getExternal<TModel, TIdentity>(externalIdentity, domainModelName, externalDomainModelType);
    }

    protected isEmptyCode(value: any): boolean {
        if (typeof value === 'string') {
            return value === '';
        } else {
            return value == null;
        }
    }

    identityIsValid(identity: Object): boolean {

        if (identity == null) {
            return false;
        }

        const identityKeys = Object.keys(identity);


        return identityKeys.every((key: string) =>
            !this.isEmptyCode(identity[key])
        )
    }

    protected async retrieveEntity(jsonIdentity: string): Promise<TModel> {
        const identity = plainToClass<TIdentity, TIdentity>(
            this._identityType as ClassType<TIdentity>, JSON.parse(jsonIdentity) as TIdentity) as TIdentity;
        return await this.externalRetriever.getExternal<TModel, TIdentity>(
            identity, this._externalDomainModelTypeName, this._externalDomainModelType);
    }

    // Necessaria se si vuole sovrascrivere il comportamento dopo la generazione automatica di autoCompleteOptions
    protected applyCustomAutomaticAutocompleteLogic() {

    }
    //#endregion PROTECTED METHODS

    //#region PRIVATE METHODS

    private buildCodeProperties(externalMetadata: ExternalMetaData): Map<string, PropertyViewModelInterface> {
        let res = this.getCodeProperties(); // metodo customizzabile
        if (res == null) {
            res = new Map<string, PropertyViewModelInterface>();
            if (this.propertyViewModels !== undefined) {
                this.propertyViewModels.forEach(pvm => {

                    externalMetadata.dependentAggregateMetaData.rootMetaData.identityNames.forEach(propertyName => {
                        const camelCasePropertyName = MetaDataUtils.toCamelCase(propertyName);
                        if (camelCasePropertyName === pvm.propertyName) {
                            res.set(pvm.propertyName, pvm);

                            // set custom getter
                            pvm.customGetter = () => {
                                if (pvm.decodePending) {
                                    return pvm.faultBackValue;
                                }
                                // Devo usare questo metodo al posto della getValue() altrimeti entro in loop
                                return pvm.internalGetValue();
                            };

                            // set custom setter
                            pvm.customSetter = async (value) => {

                                pvm.faultBackValue = value;

                                if (pvm.setIdentityOnSet && this.externalMetaData.cloneType != CloneTypes.LocalReplica) {
                                    pvm.decodePending = true;
                                    await this.setPlainPascalCaseIdentity(this.getPlainPascalCaseIdentityFromCodeProperties());
                                }
                            };
                        }
                    });
                });
            }
        }
        return res;
    }

    getPlainPascalCaseIdentityFromCodeProperties(): { [key: string]: string | number; } | null {
        const identityObject = {};
        for (const [key, pvm] of this.codeProperties) {
            const keyPascalCase = pvm.propertyMetaData.name;

            // TODO e se fosse una data?
            const isNumeric = pvm.propertyMetaData.getType() === 'Numeric';
            if (pvm.value == null) {
            } else {
                identityObject[keyPascalCase] = isNumeric ? parseFloat(pvm.value) : pvm.value;
            }

        }
        if (JSON.stringify(identityObject) == '{}') {
            return null;
        }
        return identityObject;
    }

    protected getPlainCamelCaseIdentityFromCodeProperties(): { [key: string]: string | number; } {
        const identityObject = {};
        for (const [key, pvm] of this.codeProperties) {
            const keyCamelCase = MetaDataUtils.toCamelCase(pvm.propertyMetaData.name);

            // TODO e se fosse una data?
            const isNumeric = pvm.propertyMetaData.getType() === 'Numeric';
            identityObject[keyCamelCase] = isNumeric ? parseFloat(pvm.value) : pvm.value;
        }
        return identityObject;
    }

    f8Return(jsonIdentity: string) {

        this.eventDispatcher.externalModalReturned.emit();

        // let isEnabled = this.isEnabled && Array.from(this.codeProperties.values()).every(i => i.isEnabled);

        if (jsonIdentity !== undefined && this.isEnabled) {

            this.retriveAndSetDomainModelFromJSON(jsonIdentity, false).then(() => { });
        }
        this.externalPresentationCompleted.emit();
    }

    private async retriveAndSetDomainModelFromJSON(jsonIdentity: string, fromZoom: boolean) {

        const result = await this.retrieveEntity(jsonIdentity);
        const newEntity = result as TModel;
        if (newEntity != null) {
            await this.setExternalDomainModelBackingFields(newEntity);

            this.decodeCompleted.emit();

            if (fromZoom) {
                this.postZoom();
            }
        }
    }

    private async setExternalDomainModelBackingFields(newDomainModel: TModel) {
        this._hasDecodeError = false;
        this.codeProperties.forEach((code) => {
            code.setErrors(SourceMessage.ValidationDecode, new Array<string>());
        });
        this.errors$.next([]);
        this.onErrorStatusChanged.next();
        await this.setCurrentDomainModel(newDomainModel);
    }

    private async setCurrentDomainModel(newDomainModel: TModel) {

        // setto il nuovo domain model su tutti i campi del ViewModel
        this.propertyViewModels.forEach(pvm => {
            pvm.decodePending = false;
            const oldValue = pvm.canNotifyModified;
            pvm.canNotifyModified = this.backingFieldCanNotifyModified;
            pvm.setModel(newDomainModel);
            pvm.canNotifyModified = oldValue;
        });

        // Setto anche i backing field

        for (const [key, code] of this.codeProperties) {
            if (code.setIdentityOnSet) {
                const associationMetaData = this.externalMetaData.associationProperties.find((ass) =>
                    MetaDataUtils.toCamelCase(ass.dependentPropertyName) === code.propertyName
                );
                const backingFieldPropertyName = MetaDataUtils.toCamelCase(associationMetaData.principalPropertyName);
                const backingField = (this.parent as ViewModelInterface).getProperty(backingFieldPropertyName);
                if (backingField) {
                    const oldValue = backingField.canNotifyModified;
                    backingField.canNotifyModified = this.backingFieldCanNotifyModified;
                    await backingField.setValueAsync(this.getProperty(code.propertyName).getValue());
                    backingField.canNotifyModified = oldValue;
                } else {
                    LogService.warn(`ATTENZIONE: backing field ${backingFieldPropertyName} non trovato nella classe ${this.parent?.constructor?.name}`);
                }
            }
        }

        for (const [relationKey, relation] of this.relationViewModels) {
            if (relation instanceof ExternalViewModel) {
                if (newDomainModel && newDomainModel[relationKey]) {
                    await relation.setFromModel(newDomainModel[relationKey]);
                } else {
                    await relation.setFromModel(null);
                }

            } else if (relation instanceof CollectionViewModel) {
                if (newDomainModel && newDomainModel[relationKey]?.collectionItems) {
                    await relation.setFromEntities(newDomainModel[relationKey]?.collectionItems)
                } else {
                    await relation.setFromEntities([])
                }
            }
        }

        if (this.domainModel !== newDomainModel) {
            this.domainModel = newDomainModel;

            // Infine sollevo l'evento di ExternalDomainModelChanged; l'evento è sottoscritto dal ViewModel "Padre" che
            // aggiusterà il suo grafo del domainModel sostituendo la nuova domain model
            const args = new ExternalDomainModelChangedEventArgs();
            args.domainModel = this.domainModel;
            args.senderName = this._propertyName;
            this.externalDomainModelChanged.emit(args);
            this.validate();
            // this.codeProperties.forEach((code) => {
            //     code.validate();
            // });
            // this.parent?.validate();
        } else if (this.domainModel == null) {
            // this.parent?.validate();
            this.validate();
        }
    }
    //#endregion PRIVATE METHODS
}
