import { FilterOperatorInfo } from './filter-operator-info';
import { FilterOperators } from '../../domain-models/find-options/filter';
import { Subject } from 'rxjs';
import { ModifiedSubscriberInterface } from '../modified-subscriber.interface';
import { MessageCodes } from '../../resources/message-codes';
import { MessageResourceManager } from '../../resources/message-resource-manager';

export class ZoomOperatorViewModelProperty {

  private static getDefaultNotNullableOperators(): Array<FilterOperatorInfo> {
    return [
      new FilterOperatorInfo(FilterOperators.None, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.None)),
      new FilterOperatorInfo(FilterOperators.Equals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Equals)),
      new FilterOperatorInfo(FilterOperators.NotEquals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.NotEquals)),
      new FilterOperatorInfo(FilterOperators.GreaterThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.GreaterThan)),
      new FilterOperatorInfo(FilterOperators.GreaterOrEqualThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.GreaterOrEqualThan)),
      new FilterOperatorInfo(FilterOperators.LessThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.LessThan)),
      new FilterOperatorInfo(FilterOperators.LessOrEqualThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.LessOrEqualThan)),
      new FilterOperatorInfo(FilterOperators.Between, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Between)),
      new FilterOperatorInfo(FilterOperators.In, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.In))
    ];
  } 

  private static getDefaultNullableOperators(): Array<FilterOperatorInfo> {
    return [
      new FilterOperatorInfo(FilterOperators.None, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.None)),
      new FilterOperatorInfo(FilterOperators.Equals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Equals)),
      new FilterOperatorInfo(FilterOperators.NotEquals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.NotEquals)),
      new FilterOperatorInfo(FilterOperators.GreaterThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.GreaterThan)),
      new FilterOperatorInfo(FilterOperators.GreaterOrEqualThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.GreaterOrEqualThan)),
      new FilterOperatorInfo(FilterOperators.LessThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.LessThan)),
      new FilterOperatorInfo(FilterOperators.LessOrEqualThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.LessOrEqualThan)),
      new FilterOperatorInfo(FilterOperators.IsNull, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.IsNull)),
      new FilterOperatorInfo(FilterOperators.IsNotNull, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.IsNotNull)),
      new FilterOperatorInfo(FilterOperators.Between, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Between)),
      new FilterOperatorInfo(FilterOperators.In, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.In))
    ]
  };

  private static getDefaultEnumOperators(): Array<FilterOperatorInfo> {
    return [
      new FilterOperatorInfo(FilterOperators.None, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.None)),
      new FilterOperatorInfo(FilterOperators.Equals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Equals)),
      new FilterOperatorInfo(FilterOperators.NotEquals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.NotEquals)),
      new FilterOperatorInfo(FilterOperators.In, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.In))
    ]
  };

  private static getStringOperators(): Array<FilterOperatorInfo> {
    return [
      new FilterOperatorInfo(FilterOperators.None, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.None)),
      new FilterOperatorInfo(FilterOperators.Equals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Equals)),
      new FilterOperatorInfo(FilterOperators.NotEquals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.NotEquals)),
      new FilterOperatorInfo(FilterOperators.GreaterThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.GreaterThan)),
      new FilterOperatorInfo(FilterOperators.GreaterOrEqualThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.GreaterOrEqualThan)),
      new FilterOperatorInfo(FilterOperators.LessThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.LessThan)),
      new FilterOperatorInfo(FilterOperators.LessOrEqualThan, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.LessOrEqualThan)),
      new FilterOperatorInfo(FilterOperators.IsNull, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.IsNull)),
      new FilterOperatorInfo(FilterOperators.IsNotNull, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.IsNotNull)),
      new FilterOperatorInfo(FilterOperators.Contains, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Contains), false),
      new FilterOperatorInfo(FilterOperators.StartsWith, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.StartsWith)),
      new FilterOperatorInfo(FilterOperators.EndsWith, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.EndsWith)),
      new FilterOperatorInfo(FilterOperators.Between, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Between)),
      new FilterOperatorInfo(FilterOperators.In, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.In))
    ]
  };

  private static getLimitedOperators(): Array<FilterOperatorInfo> {
    return [
      new FilterOperatorInfo(FilterOperators.None, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.None)),
      new FilterOperatorInfo(FilterOperators.Equals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Equals)),
      new FilterOperatorInfo(FilterOperators.NotEquals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.NotEquals)),
    ];
  }

  private static getInternalOperators(): Array<FilterOperatorInfo> {
    return [
      new FilterOperatorInfo(FilterOperators.None, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.None)),
    ];
  }

  private static getBoolOperators(): Array<FilterOperatorInfo> {
    return [
      new FilterOperatorInfo(FilterOperators.None, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.None)),
      new FilterOperatorInfo(FilterOperators.Equals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Equals))
    ];
  }

  private static getGuidOperators(): Array<FilterOperatorInfo> {
    return [
      new FilterOperatorInfo(FilterOperators.None, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.None)),
      new FilterOperatorInfo(FilterOperators.Equals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Equals)),
      new FilterOperatorInfo(FilterOperators.NotEquals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.NotEquals)),
    ];
  }

  private static getNullableBoolOperators(): Array<FilterOperatorInfo> {
    return [
      new FilterOperatorInfo(FilterOperators.None, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.None)),
      new FilterOperatorInfo(FilterOperators.Equals, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.Equals)),
      new FilterOperatorInfo(FilterOperators.IsNull, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.IsNull)),
      new FilterOperatorInfo(FilterOperators.IsNotNull, ZoomOperatorViewModelProperty.getLocalizedEnumShortName(FilterOperators.IsNotNull))
    ];
  }

  operatorChanged: Subject<FilterOperators> = new Subject<FilterOperators>();
  operatorEnableStatusChanged: Subject<boolean> = new Subject<boolean>();

  // Eventi per cambiamento valori degli operatori
  operatorValuesChanged: Subject<void> = new Subject();
  advancedOperatorValuesChanged: Subject<boolean> = new Subject<boolean>();

  modifiedSubscriber: ModifiedSubscriberInterface;

  values: Array<FilterOperatorInfo>;

  private _currentValue: FilterOperators;
  private _isEnabled: boolean;

  static get operatorsForInternalCollection(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getInternalOperators();
  }

  static get operatorsForInternal(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getInternalOperators();
  }

  static get operatorsForString(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getStringOperators();
  }

  static get operatorsForEnum(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getDefaultEnumOperators();
  }

  static get operatorsForNumeric(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getDefaultNotNullableOperators();
  }

  static get operatorsForExternal(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getDefaultNullableOperators();
  }

  static get operatorsForFieldWithRelation(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getLimitedOperators();
  }

  static get operatorsForNNumeric(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getDefaultNullableOperators();
  }

  static get operatorsForDateTime(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getDefaultNullableOperators();
  }

  static get operatorsForNDateTime(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getDefaultNullableOperators();
  }

  static get operatorsForBool(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getBoolOperators();
  }

  static get operatorsForGuid(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getGuidOperators();
  }

  static get operatorsForNBool(): Array<FilterOperatorInfo> {
    return ZoomOperatorViewModelProperty.getNullableBoolOperators();
  }

  private static getLocalizedEnumShortName(enumValue: FilterOperators): string {
    // return FilterOperators[enumValue];

    if (enumValue === FilterOperators.None) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_None);
    } else if (enumValue === FilterOperators.Equals) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_Equals);
    } else if (enumValue === FilterOperators.NotEquals) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_NotEquals);
    } else if (enumValue === FilterOperators.GreaterThan) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_GreaterThan);
    } else if (enumValue === FilterOperators.GreaterOrEqualThan) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_GreaterOrEqualThan);
    } else if (enumValue === FilterOperators.LessThan) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_LessThan);
    } else if (enumValue === FilterOperators.LessOrEqualThan) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_LessOrEqualThan);
    } else if (enumValue === FilterOperators.IsNull) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_IsNull);
    } else if (enumValue === FilterOperators.IsNotNull) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_IsNotNull);
    } else if (enumValue === FilterOperators.Contains) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_Contains);
    } else if (enumValue === FilterOperators.StartsWith) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_StartsWith);
    } else if (enumValue === FilterOperators.EndsWith) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_EndsWith);
    } else if (enumValue === FilterOperators.Between) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_Between);
    } else if (enumValue === FilterOperators.In) {
      return MessageResourceManager.Current.getMessage(MessageCodes.FilterOperators_In);
    }
    return '';
  }

  get currentValue(): FilterOperators {
    return this._currentValue;
  }

  set currentValue(value: FilterOperators) {
    if (this._currentValue != value) {
      this._currentValue = value;
      this.operatorChanged.next(value);
      this.modifiedSubscriber.notifyModified();
    }
  }

  get isEnabled(): boolean {
    return this._isEnabled;
  }

  set isEnabled(isEnabled: boolean) {
    if (this._isEnabled != isEnabled) {
      this._isEnabled = isEnabled;
      this.operatorEnableStatusChanged.next(isEnabled);
    }
  }

  constructor(operatorList: Array<FilterOperatorInfo>, modifiedSubscriber: ModifiedSubscriberInterface) {
    this.values = operatorList;
    this.modifiedSubscriber = modifiedSubscriber;
    this.currentValue = FilterOperators.None;
    this.isEnabled = true;
  }

}
